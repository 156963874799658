import { useEffect, useRef } from 'react';
import io from 'socket.io-client';
import useAuthStore from '../store/auth';

const SocketManager = () => {
  const { auth, setSocket, deviceUniqueId, socket } = useAuthStore((state) => state);
  const socketRef = useRef(null);

  useEffect(() => {
    // Initialize the socket connection with placeholders
    const newSocket = io('https://devapi.chat.brrm.eu/socket/chat', {
      path: '/socket.io',
      // retries: 1,
      // ackTimeout: 3000,
      autoConnect: false,
      transports: ['websocket'],
      query: {
        token: auth?.token,
        deviceUnique: deviceUniqueId
      },
    });

    console.log('socket');

    console.log("token: ", auth.token, "deviceId: ", deviceUniqueId)

    if (auth.token && deviceUniqueId) {
      newSocket.connect();
    }

    newSocket.on('connect', () => {
      console.log('connected');
      setSocket(newSocket);
      socketRef.current = newSocket;
    });

    newSocket.on('connect_error', (err) => {
      // revert to classic upgrade
      //socket.io.opts.transports = ["polling", "websocket"];
      console.log('connect_error');
      console.log(err);
      //socket.disconnect();
    });

    return () => {
      newSocket.disconnect(); // Disconnect the socket when the component unmounts
      setSocket(null);
    };
  }, [auth.token, deviceUniqueId, setSocket]);

  const disconnectSocket = () => {
    if (socketRef.current) {
      console.log('diskonektujem')
      console.log(socketRef.current)
      console.log(socket)
      socketRef.current.disconnect();
      setSocket(null);
    }
  };

  return null;
};

export default SocketManager;